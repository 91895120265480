import { ChangeEvent, forwardRef } from 'react';

import { Box } from '../layout';
import { FieldError } from './FieldError';
import { InputTextBase } from './InputText/InputTextBase';
import { InputPhoneProps } from './interface-input';

export const InputPhoneNumber = forwardRef<HTMLInputElement, InputPhoneProps>(
  ({ onChange, value: _value, ...inputProps }: InputPhoneProps, ref) => {
    return (
      <Box width="100%">
        <Box position="relative" display="flex" height="48px">
          <InputTextBase
            type="tel"
            {...inputProps}
            name="phoneNumber"
            placeholder="000 000 0000"
            padding="4px 16px"
            ref={ref}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              onChange(value.replace(/\D/g, ''))
            }
          />
        </Box>
        <FieldError fieldState={inputProps.fieldState} isSubmitted={inputProps.isSubmitted} />
      </Box>
    );
  },
);

InputPhoneNumber.displayName = 'InputPhoneNumber';
