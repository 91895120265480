import { useEffect, useState } from 'react';
import { FieldError, FieldValues, Path, PathValue } from 'react-hook-form';

import {
  Box,
  Icon,
  IconCountry,
  InputBorder,
  InputPhoneNumberControl,
  Typography,
} from '@core/component';
import { useOptionalState } from '@core/hook';
import { EColor, ECountry, EIcon, ESize, EZIndexName } from '@core/type';
import { formatPhoneNumberNational, getInternationalPrefix } from '@core/util';

import { ModalUpdateCountry } from '../modal/modal-update-country';
import { InputPhoneWithCountryProps } from './interface-phone-field';

export const InputPhoneWithCountry = <T extends FieldValues>({
  control,
  disabled,
  watch,
  isSubmitted,
  required,
  setValue,
  trigger,
  isPrefilled = false,
  countryKey = 'phone.countryIso' as Path<T>,
  numberKey = 'phone.number' as Path<T>,
  errors,
  state,
}: InputPhoneWithCountryProps<T>) => {
  const [isOpen, setIsOpen] = useOptionalState(state, false);

  const [isCountryUpdated, setIsCountryUpdated] = useState(false);

  const countryIso = watch(countryKey) as ECountry;
  const format = (phoneNumber: string) => formatPhoneNumberNational(countryIso, phoneNumber);

  const updateCountry = (country: ECountry) => {
    setValue(countryKey, country as PathValue<T, Path<T>>);
    setIsOpen(false);
    setIsCountryUpdated(true);
  };

  const handleOpenModal = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    void trigger();
  }, [watch(numberKey)]);

  return (
    <Box display={'flex'} gap={'8px'}>
      <Box
        position="relative"
        display="flex"
        gap="8px"
        alignItems="center"
        paddingLeft="16px"
        paddingRight="8px"
        height="48px"
        cursor={disabled ? 'auto' : 'pointer'}
        onClick={handleOpenModal}
        backgroundColor={
          disabled ? EColor.SECONDARY : { semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }
        }
      >
        <InputBorder
          zIndex={EZIndexName.OVER_DEFAULT}
          isSuccess={isCountryUpdated || isPrefilled}
        />
        <IconCountry country={countryIso} width={20} height={20} />
        <Typography>{`+${getInternationalPrefix(countryIso)}`}</Typography>
        <Icon type={EIcon.ANGLE} size={ESize.MD} />
      </Box>

      <InputPhoneNumberControl
        name={numberKey}
        control={control}
        disabled={disabled}
        required={required}
        format={format}
        isSubmitted={isSubmitted}
        error={(errors?.['phone'] ?? errors?.['number'] ?? errors?.[numberKey]) as FieldError}
      />

      <ModalUpdateCountry
        isOpen={isOpen}
        onSelect={updateCountry}
        selectedCountry={countryIso}
        onCloseModal={() => setIsOpen(false)}
      />
    </Box>
  );
};
