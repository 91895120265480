import { Controller } from 'react-hook-form';

import { InputPhoneNumber } from '../../input';
import { InputPhoneControlProps } from './interface-form-input';

export const InputPhoneNumberControl = ({
  name,
  control,
  isSubmitted,
  error,
  ...inputProps
}: InputPhoneControlProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <InputPhoneNumber
        field={field}
        fieldState={{ ...fieldState, error: fieldState.error ?? error }}
        {...inputProps}
        onChange={field.onChange}
        isSubmitted={isSubmitted || fieldState.isTouched}
      />
    )}
  />
);

InputPhoneNumberControl.displayName = 'InputPhoneNumberControl';
