import useTranslation from 'next-translate/useTranslation';
import { ChangeEventHandler, useDeferredValue, useEffect, useState } from 'react';

import { Box, Icon, IconCountry, InputBase, Modal, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useQueryApi } from '@core/hook';
import { EColor, EColorName, ECountry, EIcon, ESize, ETypographyVariant } from '@core/type';
import { CountriesEntity, CountryEntity, EQueryKey } from '@core/type/api';

import { ModalUpdateCountryProps } from '../interface-modal';

export const ModalUpdateCountry = ({
  isOpen,
  onCloseModal,
  selectedCountry,
  onSelect,
}: ModalUpdateCountryProps) => {
  const { t } = useTranslation(Translate.common.MODAL);

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<CountryEntity[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const deferredSearch = useDeferredValue(searchTerm);

  const { data } = useQueryApi<CountriesEntity>(EQueryKey.COUNTRIES);

  const updateSearchTerm: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleModalClose = () => {
    onCloseModal();
    setSearchTerm('');
  };

  const updateCountry = (country: ECountry) => {
    onSelect(country);
    setSearchTerm('');
  };

  const updateSuggestion = () => {
    if (!data?.countries) {
      return;
    }

    if (!deferredSearch) {
      isFocused ? setSuggestions([]) : setSuggestions(data.countries);
      return;
    }

    const suggestedCountries = data.countries.filter((country) =>
      country.name.toLowerCase().includes(deferredSearch.toLowerCase()),
    );

    setSuggestions(suggestedCountries);
  };

  useEffect(() => {
    updateSuggestion();
  }, [data, deferredSearch, isFocused]);

  return (
    <Modal size={ESize.LG} isOpen={isOpen} onCloseModal={handleModalClose}>
      <Modal.Header>
        <Typography variant={ETypographyVariant.H4} textAlign={'center'}>
          {t(`updateLocation.country`)}
        </Typography>
      </Modal.Header>

      <Modal.Body>
        <Box padding="24px" paddingBottom="8px">
          <Box display="flex" gap="32px" marginBottom="16px" alignItems="center">
            <Icon type={EIcon.SEARCH} stroke={EColorName.OXFORD_BLUE} />
            <InputBase
              onChange={updateSearchTerm}
              color={EColorName.OXFORD_BLUE}
              placeholder={t(`updateLocation.search`)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </Box>
          <Box height="1px" backgroundColor={{ commonType: EColor.GRAY, intensity: EColor.A200 }} />
        </Box>
        <Box height="280px" padding="0 4px" overflowY="scroll">
          {suggestions.map(({ countryIso, name }) => (
            <Box
              key={countryIso}
              height="40px"
              padding="0 20px"
              backgroundColor={
                countryIso === selectedCountry && { commonType: EColor.GRAY, intensity: EColor.A50 }
              }
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              cursor="pointer"
              onClick={() => updateCountry(countryIso)}
            >
              <Box gap="32px" display="flex">
                <IconCountry country={countryIso} width={20} height={20} />
                <Typography>{name}</Typography>
              </Box>
              {countryIso === selectedCountry && <Icon type={EIcon.CHECK} color={EColor.SUCCESS} />}
            </Box>
          ))}
        </Box>
      </Modal.Body>
    </Modal>
  );
};
