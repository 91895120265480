import { Dispatch, SetStateAction, useState } from 'react';

export function useOptionalState<T>(
  state: [T, Dispatch<SetStateAction<T>>] | undefined,
  defaultValue: T,
) {
  const internalState = useState<T>(defaultValue);

  if (!state) {
    return internalState;
  }

  return state;
}
